<script lang="ts" setup>
import { spring, timeline } from 'motion'
import { publicRoutes } from '~/middleware/auth.global'

const { isSplashScreenVisible } = useSplashScreen()

const isOnPublicRoute = computed(() => publicRoutes.includes(useRoute().path))

const nuxtApp = useNuxtApp()
nuxtApp.hook('page:finish', async () => {
    if (import.meta.client) {
        const { finished } = timeline([
            ['#square', {
                borderWidth: ['18px', '0px'],
            }, {
                easing: spring({
                    stiffness: 20,
                    damping: 100,
                }),
            }],
            ['#splash-screen', {
                opacity: [1, 0],
            }, {
                duration: 0.6,
                easing: 'ease-in-out',
            }],
        ])

        finished.then(() => {
            setTimeout(() => {
                isSplashScreenVisible.value = false
            }, 600)
        })
    }
})

if (import.meta.client && !isOnPublicRoute.value) {
    timeline([
        ['#square', {
            rotate: [0, '.25turn'],
            width: ['48px', '128px'],
            borderColor: ['#000000', '#FF9D45'],
            borderWidth: ['5.5px', '18px'],
        }, {
            easing: spring({
                stiffness: 350,
                damping: 60,
            }),
        }],
        ['#square', {
            rotate: ['.25turn', '.5turn'],
            width: ['128px', '48px'],
            borderColor: ['#FF9D45', '#FDCA47'],
            borderWidth: ['18px', '5.5px'],
        }, {
            easing: spring({
                stiffness: 250,
                damping: 60,
            }),
        }],
        ['#square', {
            rotate: ['.5turn', '.75turn'],
            width: ['48px', '128px'],
            borderColor: ['#FDCA47', '#41B7DF'],
            borderWidth: ['5.5px', '18px'],
        }, {
            easing: spring({
                stiffness: 250,
                damping: 60,
            }),
        }],
        ['#square', {
            rotate: ['.75turn', '1turn'],
            width: ['128px', '48px'],
            borderColor: ['#41B7DF', '#3A4FB4'],
            borderWidth: ['18px', '5.5px'],
        }, {
            easing: spring({
                stiffness: 250,
                damping: 60,
            }),
        }],
    ], {
        direction: 'alternate',
        repeat: Infinity,
    })
}
</script>

<template>
    <div v-if="!isOnPublicRoute && isSplashScreenVisible" id="splash-screen" fixed w-100dvw h-100dvh inset-0 z-9999 flex items-center justify-center bg-white>
        <!-- Square -->
        <div id="square" w-48px aspect-square b="5.5px black" rounded-4px shadow-subtle />
    </div>
</template>
