const revisions = [
    {
        name: 'Added user ID to JWT',
        date: '2024-05-25',
    },
    {
        name: 'Changed the auth to Clerk',
        date: '2024-07-17',
    },
]

export default defineNuxtPlugin(() => {
    if (import.meta.server) {
        // This cookie is `Same-site: Lax`, `HttpOnly`, and `Secure`
        const secureSessionTokenCookie = useCookie('__session', { sameSite: 'lax', secure: true })
        const externalTokenCookie = useCookie('__tela-api-jwt')
        const revisionCookie = useCookie('tela-auth-revision')
        const currentRevision = getLatestRevision()
        const { path } = useRoute()

        if (revisionCookie.value !== currentRevision.hash) {
            // If the revision cookie is not the latest, clear the session token
            externalTokenCookie.value = ''
            secureSessionTokenCookie.value = ''
            revisionCookie.value = currentRevision.hash

            if (path === '/')
                return

            navigateTo('/app/login')
        }
    }
})

function getRevisionsWithKey() {
    // Generate a hash of each revision
    return revisions.map((revision) => {
        return {
            ...revision,
            // eslint-disable-next-line node/prefer-global/buffer
            hash: Buffer.from(JSON.stringify(revision), 'utf-8').toString('base64'),
        }
    })
}

function getLatestRevision() {
    // Order by date desc
    const sortedRevisions = [...getRevisionsWithKey()].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    const [latestRevision] = sortedRevisions
    return latestRevision
}
