import { edenTreaty } from '@elysiajs/eden'
import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'
import type { EdenTreaty } from '@elysiajs/eden/treaty'
import type { TelaAPI } from '../../api/src/types'

/**
 * Configuration options for the API client
 */
interface APIOptions {
    retry?: number
    timeout?: number
    stream?: boolean
}

/**
 * Hook to create and use the API client
 */
export function useAPI() {
    /**
     * Creates an API client instance
     * @param options - Configuration options for the API client
     * @returns An instance of the API client
     */
    const createAPIClient = (options: APIOptions = { retry: 4 }) => {
        const { apiUrl } = useRuntimeConfig().public
        const sessionTokenCookie = useCookie('__session')

        return edenTreaty<TelaAPI>(apiUrl, {
            fetcher: createCustomFetcher(sessionTokenCookie, options),
        })
    }

    return { api: createAPIClient }
}

/**
 * Creates a custom fetcher for the API client
 * @param sessionTokenCookie - The session token cookie
 * @param options - API client options
 * @returns A custom fetcher function
 */
function createCustomFetcher(sessionTokenCookie: Ref<string | null | undefined>, options: APIOptions): EdenTreaty.Config['fetcher'] {
    return async (url: string | Request | URL, requestOptions?: RequestInit) => {
        requestOptions ??= {} as Record<string, any>

        try {
            const token = await getToken(sessionTokenCookie)

            requestOptions.headers = {
                ...requestOptions.headers,
                Authorization: `Bearer ${token}`,
            }

            const res = await $fetch.raw(url as NitroFetchRequest, {
                ...requestOptions,
                ...getFetchOptions(options),
                onResponse: handleResponse,
                onResponseError: handleResponseError,
            } as NitroFetchOptions<NitroFetchRequest>)

            return res as Response
        }
        catch (e) {
            if ((e instanceof Error && 'response' in e && e.response instanceof Response)) {
                return e.response
            }
            else {
                const log = useLog()
                log.error('Unknown request error', { error: e })
                throw e
            }
        }
    }
}

/**
 * Retrieves the session token
 * @param sessionTokenCookie - The session token cookie
 * @returns The session token
 */
export async function getToken(sessionTokenCookie: Ref<string | null | undefined>): Promise<string> {
    const timeout = 6000
    const interval = 200
    const startTime = Date.now()

    const pollToken = async (): Promise<string> => {
        if (Date.now() - startTime >= timeout)
            throw new Error('Failed to retrieve session token')

        if (import.meta.server) {
            const token = await $fetch('/api/auth/clerk-token', { headers: useRequestHeaders() })
            if (token) {
                return token
            }
            else {
                await wait(interval)
                return pollToken()
            }
        }
        else if (sessionTokenCookie.value) {
            return sessionTokenCookie.value
        }

        await wait(interval)
        return pollToken()
    }

    return pollToken()
}

/**
 * Generates fetch options based on the provided API options
 * @param options - API client options
 * @returns Fetch options
 */
function getFetchOptions(options: APIOptions): Partial<NitroFetchOptions<NitroFetchRequest>> {
    return {
        responseType: options.stream ? 'stream' : undefined,
        retry: options.retry ?? 0,
        retryDelay: 1000,
        timeout: options.timeout,
    }
}

/**
 * Handles successful responses
 */
function handleResponse({ response }: { response: Response & { _data: any } }) {
    if (response.headers.get('content-type')?.includes('application/json')) {
        response.json = async () => response._data
    }
    else {
        response.text = async () => response._data
    }
}

/**
 * Handles response errors
 */
function handleResponseError({ response }: { response: Response & { _data: any } }) {
    response.text = async () => response._data
}
