export default defineAppConfig({
    ui: {
        primary: 'black',
        selectMenu: {
            rounded: 'rounded-8px',
            ring: 'ring-0 b-1 b-bordercolor',
            background: '!bg-red',
        },
        button: {
            variant: {
                solid: 'text-black bg-white shadow-none b-1 b-#EDEDED hover:bg-#F7F7F7 hover:b-#DEDEDE transition disabled:bg-white disabled:cursor-not-allowed ',
            },
            size: {
                '2xs': 'text-xs',
                'xs': 'text-xs',
                'sm': 'text-[13px] h-32px',
                'md': 'text-[13px] h-40px',
                'lg': 'text-sm',
                'xl': 'text-base',
            },
            padding: {
                md: '!px-16px !py-15px',
            },
            default: {
                size: 'md',
                variant: 'solid',
                color: 'primary',
                loadingIcon: 'i-ph-arrows-clockwise',
            },
        },
        input: {
            base: '!ring-0 !shadow-none !bg-pink',
            padding: {
                sm: '!pb-50px',
            },
        },
        modal: {
            container: 'flex min-h-full items-center sm:items-center justify-center text-center',
        },
        notifications: {
            position: 'right-0',
            width: 'sm:!w-[420px]',
        },
        notification: {
            background: '!bg-[#031E22]',
            container: 'b-1 b-[#01292F/08]',
            rounded: 'rounded-8px',
            title: 'text-16px text-white font-semibold tracking--0.01em leading-20px',
            description: 'text-14px text-white/60 leading-1.08em',
            ring: 'ring-0',
        },
        toggle: {
            container: {
                base: 'pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white dark:bg-gray-900 shadow ring-0 transition ease-in-out duration-200',
            },
        },
        tooltip: {
            popper: {
                arrow: true,

            },
            rounded: 'rounded-full',
            arrow: {
                ring: 'before:ring-[#000000]',
                background: 'before:!bg-[#000000]',
            },
            background: '!bg-[#01292F]',
            ring: '!ring-1 !ring-black',
            base: '[@media(pointer:coarse)]:hidden h-6 px-12px py-4px truncate relative text-10px font-semibold !text-white',
            shadow: 'shadow-none',
        },
    },
})
