import { useUser } from 'vue-clerk'
import type { UserResource as ClerkUser } from '@clerk/types'
import type { User } from 'tela-api/src/database/schemas/user'
import * as Sentry from '@sentry/nuxt'

export function useUserData() {
    const userData = computed(() => {
        if (!import.meta.client)
            return null

        try {
            const { user } = useUser()
            if (!user.value)
                return null

            return {
                ...user.value,
                createOrganizationsLimit: 1,
                imageUrl: user.value.hasImage ? user.value.imageUrl : (user.value?.externalAccounts?.find(({ imageUrl }) => !!imageUrl)?.imageUrl ?? user.value.imageUrl),
            } as unknown as ClerkUser
        }
        catch (error) {
            return null
        }
    })

    watchOnce(userData, (user) => {
        if (!user)
            return

        Sentry.setUser({
            id: user.id,
            email: user.primaryEmailAddress?.emailAddress ?? '',
            username: user.fullName ?? '',
            ip_address: '{{auto}}',
        })
    })

    async function fetchUserByEmail(email: string) {
        const { api } = useAPI()
        const fetchResponse = await api().user['by-email'][email].get()

        if (fetchResponse.error)
            throw fetchResponse.error

        return {
            email: fetchResponse.data?.emailAddresses?.[0]?.emailAddress ?? '',
            name: `${fetchResponse.data?.firstName ?? ''} ${fetchResponse.data?.lastName ?? ''}`,
            avatar_url: fetchResponse.data?.imageUrl,
            createdAt: new Date(fetchResponse.data?.createdAt ?? ''),
            updatedAt: new Date(fetchResponse.data?.updatedAt ?? ''),
        } as User
    }

    return {
        fetchUserByEmail,
        user: userData,
    }
}
