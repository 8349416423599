import { createClient } from '@vercel/edge-config'
import { resguard } from 'resguard'

export default defineNuxtPlugin({
    parallel: true,
    setup: async () => {
        const stateFromAppConfig = useAppConfig().featureFlags
        const isDevelopment = useRuntimeConfig().public.isDevelopment

        const featureFlags = useState<FeatureFlags>('feature-flags', () => stateFromAppConfig)

        if (isDevelopment && stateFromAppConfig !== undefined) {
            featureFlags.value = { ...featureFlags.value, ...stateFromAppConfig }
        }
        else if (import.meta.server && useRuntimeConfig().edgeConfig) {
            const edgeConfigConnectionString = useRuntimeConfig().edgeConfig
            const stateFromEdgeConfig = await resguard(createClient(edgeConfigConnectionString).getAll() as Promise<FeatureFlags>)

            if (stateFromEdgeConfig.error)
                console.error(stateFromEdgeConfig.error)
            else
                featureFlags.value = { ...featureFlags.value, ...stateFromEdgeConfig.data }
        }

        function getFeatureFlag(flag: FeatureFlagKey) {
            return computed(() => isFeatureEnabled(featureFlags.value[flag]))
        }

        function isFeatureEnabled(flagValue: FeatureFlagValue) {
            if (typeof flagValue === 'boolean') {
                return flagValue
            }
            else if (Array.isArray(flagValue)) {
                for (const rule of flagValue) {
                    if ('email' in rule) {
                        const { user } = useUserData()
                        const userEmail = user.value?.emailAddresses?.[0].emailAddress

                        if (!userEmail)
                            continue

                        if (rule.email.some(email => userEmail.endsWith(email)))
                            return true
                    }
                }
            }

            return false
        }

        return {
            provide: {
                getFeatureFlag,
            },
        }
    },
})
