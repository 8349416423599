import revive_payload_client_AHGWdjDekj from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.33_drizzle-orm@0.31.0_@cloudflare+workers_ej4l2odvsyofksor4kc4hwcaoa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_g0XkQqmSY6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.33_drizzle-orm@0.31.0_@cloudflare+workers_ej4l2odvsyofksor4kc4hwcaoa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IDjcLH9SRA from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.33_drizzle-orm@0.31.0_@cloudflare+workers_ej4l2odvsyofksor4kc4hwcaoa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import clerk_lzqarMDdN6 from "/vercel/path0/node_modules/.pnpm/vue-clerk@0.6.9_@clerk+backend@1.11.1_react@18.3.1__react@18.3.1_vue@3.5.1_typescript@5.4.5_/node_modules/vue-clerk/dist/nuxt/runtime/plugins/clerk.js";
import payload_client_kXktldgMfC from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.33_drizzle-orm@0.31.0_@cloudflare+workers_ej4l2odvsyofksor4kc4hwcaoa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2TJkDNv9pg from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.33_drizzle-orm@0.31.0_@cloudflare+workers_ej4l2odvsyofksor4kc4hwcaoa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_plDgjKS2VL from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.33_drizzle-orm@0.31.0_@cloudflare+workers_ej4l2odvsyofksor4kc4hwcaoa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_lQSsoq9GEM from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.33_drizzle-orm@0.31.0_@cloudflare+workers_ej4l2odvsyofksor4kc4hwcaoa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/packages/application/.nuxt/components.plugin.mjs";
import prefetch_client_HNmglaW49k from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.33_drizzle-orm@0.31.0_@cloudflare+workers_ej4l2odvsyofksor4kc4hwcaoa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_Ynw35Ohmry from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.0_axios@1.7.4_change-case@4.1.2_magicast@0.3.5_rollup@4.21.3_vite@5.4.5_@types+_ynoyhizlsxqzsch3c7ag6ofdzq/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_BmC4EysGAB from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.0_axios@1.7.4_change-case@4.1.2_magicast@0.3.5_rollup@4.21.3_vite@5.4.5_@types+_ynoyhizlsxqzsch3c7ag6ofdzq/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_DZEN677NQl from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.0_axios@1.7.4_change-case@4.1.2_magicast@0.3.5_rollup@4.21.3_vite@5.4.5_@types+_ynoyhizlsxqzsch3c7ag6ofdzq/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_dG2CXUnPpM from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.0_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_g6ns7X1PTz from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.21.3_vite@5.4.5_@types+node@18.19.33_terser@5.32.0___hhv4apfyvqpq3znwhz643ln3qu/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import unocss_MzCDxu9LMj from "/vercel/path0/packages/application/.nuxt/unocss.mjs";
import sentry_client_ir864gEmQP from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.26.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._hn5y62n4mbin7yqbtmwdjubfiu/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/packages/application/.nuxt/sentry-client-config.mjs";
import plugin_client_efbnaAFvf0 from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_prod_client_jdmYu67iFO from "/vercel/path0/node_modules/.pnpm/nuxt-monaco-editor@1.2.9_magicast@0.3.5_monaco-editor@0.49.0_rollup@4.21.3_vite@5.4.5_@types+_yrbmxsahvoeqzi65qzlscdrloi/node_modules/nuxt-monaco-editor/dist/runtime/plugin-prod.client.mjs";
import plugin_client_KZPjDyUSwq from "/vercel/path0/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.3_magicast@0.3.5_rollup@4.21.3_vue@3.5.1_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import plugin_ziYcKwnJdq from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import _0_force_logout_per_revision_TeCF2tpkEo from "/vercel/path0/packages/application/plugins/0.force-logout-per-revision.ts";
import feature_flags_3X7A4FWWIa from "/vercel/path0/packages/application/plugins/feature-flags.ts";
import input_autowidth_bJoGoxmQ7Q from "/vercel/path0/packages/application/plugins/input-autowidth.ts";
import liveblocks_ch8igrfBKq from "/vercel/path0/packages/application/plugins/liveblocks.ts";
import test_id_TLE30LqioL from "/vercel/path0/packages/application/plugins/test-id.ts";
export default [
  revive_payload_client_AHGWdjDekj,
  unhead_g0XkQqmSY6,
  router_IDjcLH9SRA,
  clerk_lzqarMDdN6,
  payload_client_kXktldgMfC,
  navigation_repaint_client_2TJkDNv9pg,
  check_outdated_build_client_plDgjKS2VL,
  chunk_reload_client_lQSsoq9GEM,
  components_plugin_KR1HBZs4kY,
  prefetch_client_HNmglaW49k,
  slideovers_Ynw35Ohmry,
  modals_BmC4EysGAB,
  colors_DZEN677NQl,
  plugin_client_dG2CXUnPpM,
  plugin_g6ns7X1PTz,
  unocss_MzCDxu9LMj,
  sentry_client_ir864gEmQP,
  sentry_client_config_o34nk2sJbg,
  plugin_client_efbnaAFvf0,
  plugin_prod_client_jdmYu67iFO,
  plugin_client_KZPjDyUSwq,
  plugin_ziYcKwnJdq,
  _0_force_logout_per_revision_TeCF2tpkEo,
  feature_flags_3X7A4FWWIa,
  input_autowidth_bJoGoxmQ7Q,
  liveblocks_ch8igrfBKq,
  test_id_TLE30LqioL
]