import { default as indexVLFNh06M3cMeta } from "/vercel/path0/packages/application/pages/any2table/app/index.vue?macro=true";
import { default as indexidQkrUayBeMeta } from "/vercel/path0/packages/application/pages/any2table/index.vue?macro=true";
import { default as verifyAOMrCvjl9aMeta } from "/vercel/path0/packages/application/pages/any2table/verify.vue?macro=true";
import { default as dashboard0TrLSEL7kfMeta } from "/vercel/path0/packages/application/pages/app/dashboard.vue?macro=true";
import { default as errorVURPiQTj3uMeta } from "/vercel/path0/packages/application/pages/app/error.vue?macro=true";
import { default as _91fragment_45id_93vXfbKZK7kLMeta } from "/vercel/path0/packages/application/pages/app/fragment/[fragment-id].vue?macro=true";
import { default as indexVoNNwFj74nMeta } from "/vercel/path0/packages/application/pages/app/fragment/index.vue?macro=true";
import { default as indexzrWJlt7TpMMeta } from "/vercel/path0/packages/application/pages/app/index.vue?macro=true";
import { default as loginAXWETL6ll0Meta } from "/vercel/path0/packages/application/pages/app/login.vue?macro=true";
import { default as _91project_45id_93H0nA0FmKtJMeta } from "/vercel/path0/packages/application/pages/app/project/[project-id].vue?macro=true";
import { default as craftXo9E2wrweKMeta } from "/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/craft.vue?macro=true";
import { default as data_45extractionpewHGqY2GvMeta } from "/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/data-extraction.vue?macro=true";
import { default as evaluationjKyqfSjFrGMeta } from "/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/evaluation.vue?macro=true";
import { default as testcXfqT28NoQMeta } from "/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/test.vue?macro=true";
import { default as _91prompt_45id_939umtKeSLaBMeta } from "/vercel/path0/packages/application/pages/app/prompt/[prompt-id].vue?macro=true";
import { default as indexVtpaDeze5nMeta } from "/vercel/path0/packages/application/pages/app/settings/index.vue?macro=true";
import { default as membersezEMPcj8bQMeta } from "/vercel/path0/packages/application/pages/app/settings/members.vue?macro=true";
import { default as workspaceYHKfexU8oZMeta } from "/vercel/path0/packages/application/pages/app/settings/workspace.vue?macro=true";
import { default as indexPUGoP8ctVOMeta } from "/vercel/path0/packages/application/pages/index.vue?macro=true";
import { default as onboardingnOcrA8PNRMMeta } from "/vercel/path0/packages/application/pages/onboarding.vue?macro=true";
import { default as workspacesyYf0ttiAKTMeta } from "/vercel/path0/packages/application/pages/workspaces.vue?macro=true";
export default [
  {
    name: "any2table-app",
    path: "/any2table/app",
    component: () => import("/vercel/path0/packages/application/pages/any2table/app/index.vue").then(m => m.default || m)
  },
  {
    name: "any2table",
    path: "/any2table",
    meta: indexidQkrUayBeMeta || {},
    component: () => import("/vercel/path0/packages/application/pages/any2table/index.vue").then(m => m.default || m)
  },
  {
    name: "any2table-verify",
    path: "/any2table/verify",
    meta: verifyAOMrCvjl9aMeta || {},
    component: () => import("/vercel/path0/packages/application/pages/any2table/verify.vue").then(m => m.default || m)
  },
  {
    name: "app-dashboard",
    path: "/app/dashboard",
    meta: dashboard0TrLSEL7kfMeta || {},
    component: () => import("/vercel/path0/packages/application/pages/app/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "app-error",
    path: "/app/error",
    meta: errorVURPiQTj3uMeta || {},
    component: () => import("/vercel/path0/packages/application/pages/app/error.vue").then(m => m.default || m)
  },
  {
    name: "app-fragment-fragmentid",
    path: "/app/fragment/:fragmentid()",
    component: () => import("/vercel/path0/packages/application/pages/app/fragment/[fragment-id].vue").then(m => m.default || m)
  },
  {
    name: "app-fragment",
    path: "/app/fragment",
    component: () => import("/vercel/path0/packages/application/pages/app/fragment/index.vue").then(m => m.default || m)
  },
  {
    name: "app",
    path: "/app",
    meta: indexzrWJlt7TpMMeta || {},
    component: () => import("/vercel/path0/packages/application/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: "app-login",
    path: "/app/login",
    meta: loginAXWETL6ll0Meta || {},
    component: () => import("/vercel/path0/packages/application/pages/app/login.vue").then(m => m.default || m)
  },
  {
    name: "app-project-projectid",
    path: "/app/project/:projectid()",
    meta: _91project_45id_93H0nA0FmKtJMeta || {},
    component: () => import("/vercel/path0/packages/application/pages/app/project/[project-id].vue").then(m => m.default || m)
  },
  {
    name: "app-prompt-promptid",
    path: "/app/prompt/:promptid()",
    meta: _91prompt_45id_939umtKeSLaBMeta || {},
    component: () => import("/vercel/path0/packages/application/pages/app/prompt/[prompt-id].vue").then(m => m.default || m),
    children: [
  {
    name: "app-prompt-promptid-craft",
    path: "craft",
    component: () => import("/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/craft.vue").then(m => m.default || m)
  },
  {
    name: "app-prompt-promptid-data-extraction",
    path: "data-extraction",
    meta: data_45extractionpewHGqY2GvMeta || {},
    component: () => import("/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/data-extraction.vue").then(m => m.default || m)
  },
  {
    name: "app-prompt-promptid-evaluation",
    path: "evaluation",
    component: () => import("/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/evaluation.vue").then(m => m.default || m)
  },
  {
    name: "app-prompt-promptid-test",
    path: "test",
    component: () => import("/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/test.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app-settings",
    path: "/app/settings",
    component: () => import("/vercel/path0/packages/application/pages/app/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "app-settings-members",
    path: "/app/settings/members",
    meta: membersezEMPcj8bQMeta || {},
    component: () => import("/vercel/path0/packages/application/pages/app/settings/members.vue").then(m => m.default || m)
  },
  {
    name: "app-settings-workspace",
    path: "/app/settings/workspace",
    meta: workspaceYHKfexU8oZMeta || {},
    component: () => import("/vercel/path0/packages/application/pages/app/settings/workspace.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexPUGoP8ctVOMeta || {},
    component: () => import("/vercel/path0/packages/application/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingnOcrA8PNRMMeta || {},
    component: () => import("/vercel/path0/packages/application/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "workspaces",
    path: "/workspaces",
    meta: workspacesyYf0ttiAKTMeta || {},
    component: () => import("/vercel/path0/packages/application/pages/workspaces.vue").then(m => m.default || m)
  }
]