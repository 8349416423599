import { useAuth, useOrganization } from 'vue-clerk'

export const publicRoutes = [
    '/app/login',
    '/',
]

export default defineNuxtRouteMiddleware((to) => {
    const { isSignedIn, orgId } = useAuth()
    const env = useRuntimeConfig()
    const { organization } = useOrganization()

    if (to.path.includes('/any2table'))
        return

    const isOrganizationSet = import.meta.server ? Boolean(orgId.value) : Boolean(orgId.value) || Boolean(organization.value)
    const isAuthenticated = Boolean(isSignedIn.value || env.public.histoire)
    const isGoingToPublicRoute = publicRoutes.includes(to.path)

    if (!isOrganizationSet && isAuthenticated && !isGoingToPublicRoute && to.path !== '/workspaces')
        return navigateTo('/workspaces')

    if (isGoingToPublicRoute && isAuthenticated)
        return navigateTo('/app')

    if (isAuthenticated && isOrganizationSet && to.path === '/workspaces')
        return navigateTo('/app')

    if (!isGoingToPublicRoute && !isAuthenticated)
        return navigateTo('/app/login')
})
