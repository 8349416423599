import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.33_drizzle-orm@0.31.0_@cloudflare+workers_ej4l2odvsyofksor4kc4hwcaoa/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45any2table_45global from "/vercel/path0/packages/application/middleware/auth-any2table.global.ts";
import auth_45global from "/vercel/path0/packages/application/middleware/auth.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.33_drizzle-orm@0.31.0_@cloudflare+workers_ej4l2odvsyofksor4kc4hwcaoa/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45any2table_45global,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/vercel/path0/node_modules/.pnpm/vue-clerk@0.6.9_@clerk+backend@1.11.1_react@18.3.1__react@18.3.1_vue@3.5.1_typescript@5.4.5_/node_modules/vue-clerk/dist/nuxt/runtime/middleware/auth.js"),
  guest: () => import("/vercel/path0/node_modules/.pnpm/vue-clerk@0.6.9_@clerk+backend@1.11.1_react@18.3.1__react@18.3.1_vue@3.5.1_typescript@5.4.5_/node_modules/vue-clerk/dist/nuxt/runtime/middleware/guest.js")
}